<div id="reset-password" fxLayout="column">

    <div id="reset-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="reset-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="/dist/assets/images/logos/fuse.svg">
            </div>

            <div class="title">REIMPOSTA LA TUA PASSWORD</div>

            <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate>

                <!--
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('email').hasError('required')">
                        L'email è obbligatoria
                    </mat-error>
                    <mat-error *ngIf="resetPasswordForm.get('email').hasError('email')">
                        L'indirizzo email non è valido
                    </mat-error>
                </mat-form-field> -->

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        Password è obbligatoria
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password (Conferma)</mat-label>
                    <input matInput type="password" formControlName="passwordConfirm">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').hasError('required')">
                        Password di conferma è obbligatoria
                    </mat-error>
                    <mat-error *ngIf="!resetPasswordForm.get('passwordConfirm').hasError('required') &&
                                       resetPasswordForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                        Le password non corrispondono
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button class="submit-button" (click)="sendRequest()" color="accent" aria-label="RESET MY PASSWORD" [disabled]="resetPasswordForm.invalid">
                    RESETTA LA MIA PASSWORD
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/login'">Torna al login</a>
            </div>

        </div>

    </div>

</div>