import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { Scheda, DatabaseService, Field, Validator } from "@core/database";
import { map } from "rxjs/operators";
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from "@angular/forms";
import { NotificheService } from "app/services/notifiche.service";

@Component({
  selector: "automated-form",
  templateUrl: "./automated-form.component.html",
  styleUrls: ["./automated-form.component.scss"],
})
export class AutomatedFormComponent implements OnInit {
  @Input() schedaId: number;
  @Input() data: any;
  @Output() confirmed: EventEmitter<any> = new EventEmitter<any>();
  scheda$: Observable<Scheda>;
  formScheda: UntypedFormGroup;

  constructor(
    private database: DatabaseService,
    private notifiche: NotificheService
  ) {}

  ngOnInit(): void {
    this.scheda$ = this.database.schede.selectByPrimaryKey(this.schedaId).pipe(
      map((scheda) => {
        this.elaborateForm(scheda);
        return scheda;
      })
    );
  }

  confirmForm(): void {
    if (!this.formScheda.valid) {
      this.notifiche.errore("controlla i valori inseriti");
      return;
    }

    this.confirmed.emit(this.formScheda.value);
  }

  private elaborateForm(scheda: Scheda): void {
    const controls: any = {};

    for (const field of scheda.fields) {
      controls[field.DataModel] = this.convertField(field);
    }

    console.log(controls);
    this.formScheda = new UntypedFormGroup(controls);
  }

  private convertField(field: Field): UntypedFormControl {
    return new UntypedFormControl(
      this.getValue(field.DataModel),
      this.getValidators(field.validators)
    );
  }

  getValue(fieldName: string): any {
    const path = fieldName.split(".");
    let elem: any = this.data;
    if (!elem) return null;
    for (const name of path) {
      if (!elem[name]) {
        return "";
      }
      elem = elem[name];
    }

    return elem;
  }

  getValidators(validators: Validator[]): any[] {
    if (!validators) {
      return [];
    }

    const resValids: any[] = [];

    for (const validator of validators) {
      switch (validator.type) {
        case "required":
          resValids.push(Validators.required);
          break;
      }
    }

    return resValids;
  }
}
