import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { Settings } from "@shared/models";
import { ApiService } from "app/services/api.service";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  private _settings$: BehaviorSubject<Settings> = new BehaviorSubject<Settings>(
    null
  );
  settings$: Observable<Settings> = this._settings$.asObservable();
  constructor(private api: ApiService) {}

  update(silent: boolean = false): void {
    this.api.send("/settings", null, silent).subscribe((data) => {
      this._settings$.next(data as Settings);
    });
  }
}
