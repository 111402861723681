import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DatabaseService } from '@core/database';

@Pipe({
  name: 'stati'
})
export class StatoPipe implements PipeTransform {

  constructor(private database: DatabaseService) {}

  transform(value: any, args?: any): Observable<string> {
    if (value === -1) {
      return of('IN INVIO...');
    }
    return this.database.stati
      .selectByPrimaryKey(value)
      .pipe(map((item) => (item ? item.label : '')));
  }

}
