import { Injectable } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { BehaviorSubject, Subject} from 'rxjs';
import { Appointment } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsListService {

  private _appointments$: BehaviorSubject<Appointment[]> = new BehaviorSubject<Appointment[]>(null);
  appointments$ = this._appointments$.asObservable();

  constructor(
    private api: ApiService
  ) { }

  update(silent: boolean = false ): void{
    
    this.api.send('/appointmentList', null, silent).subscribe((data) => {      
      this._appointments$.next(data.response as Appointment[]);
    });
  }

}
