export const locale = {
    lang: 'it',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'HELPDESK': 'Helpdesk',
            'HOME'        : {
                'TITLE': 'Home'                
            },
            'PRODUCTS'        : {
                'TITLE': 'Prodotti'                
            },
            'INASSISTANCE'        : {
                'TITLE': 'In assistenza',
                'PENDING': 'prodotti in riparazione',
                'HISTORY': 'storico'                
            },
            'SUPPORT'        : {
                'TITLE': 'Supporto',
                'PENDING': 'Ticket aperti',
                'HISTORY': 'Storico',
                'NEW': '+ Apri ticket'                   
            },
            'APPOINTMENTS'        : {
                'TITLE': 'Appuntamenti',
                'COMING': 'Prossimi appuntamenti',
                'PENDING': 'Appuntamenti in approvazione',
                'HISTORY': 'Storico',
                'NEW': '+ Richiedi appuntamento'    
            },
            'MESSAGES'        : {
                'TITLE': 'Messaggi/notifiche'                
            },
            'SHOP_QUEUE'        : {
                'TITLE': 'Check-in assistenza'                
            }            
        }
    }
};
