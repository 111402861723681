import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificheService {

  constructor(private snackBar: MatSnackBar) { }

  notifica(message: string, action?: string ): MatSnackBarRef<SimpleSnackBar> 
  {
    if (!action) {
      action = 'OK';
    }
    return this.snackBar.open(message, action, {
      duration: 5000
    });
  }

  errore(message: string, action?: string ): MatSnackBarRef<SimpleSnackBar>
  {
    if (!action) {
      action = 'chiudi';
    }    
    return this.snackBar.open(message, action, {
      duration: 5000
    });
  }
}
