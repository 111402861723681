import { DetailAppointment } from './detail-appointment';

export interface Appointment {
    idapp:        number;
    dtime:      string;
    indirizzo:    string;
    responsabile: string;
    telefono:     string;
    prog:         number;
    stato:        number;
    detail:       DetailAppointment;
}
