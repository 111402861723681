import { IndexFunctions } from './index-functions';
import { IndexTypes } from './index-types';

export class IndexDeclaration {
  name: string;
  fieldsName: string[];
  type: IndexTypes = IndexTypes.normal;

  constructor(name: string, ...fieldsName: string[]) {
    this.name = name;
    this.fieldsName = fieldsName;
  }

  public generateKey(object: any): string {
    const outKey: string[] = [];
    for (const key of this.fieldsName) {
      outKey.push(IndexFunctions.getValue(key, object));
    }
    return outKey.join(IndexFunctions.keySeparator);
  }

  public *generateKeyArray(object: any) {
    const key = this.fieldsName[0];
    for (const val of IndexFunctions.getValueArray(key, object)) {
      yield val;
    }
  }

  public getValues(object: any): any[] {
    const values: any[] = [];
    for (const key of this.fieldsName) {
      if (key.indexOf('.') === -1) {
        values.push(object[key]);
        continue;
      }
      const keys = key.split('.');
      // è una subproperty
      let value: any = object;
      for (const subKey of keys) {
        value = value[subKey];
        if (value === undefined || value === null) {
          break;
        }
      }
      values.push(value);
    }
    return values;
  }
}
