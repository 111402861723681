<h1 mat-dialog-title>IMPOSTA IL TUO AVATAR</h1>
<div mat-dialog-content id="change-avatar">
  <div class="file-upload">
    <button
      (click)="addFiles($event)"
      aria-label="seleziona foto"
      class="add-attachment-button mb-12"
      mat-stroked-button
    >
      <mat-icon class="mr-8">camera_alt</mat-icon> Seleziona foto
    </button>
    <input
      id="custom-input"
      #file
      type="file"
      style="display: none"
      (change)="fileChangeListener($event)"
    />
  </div>
  <!-- <img-cropper #cropper [image]="data" [settings]="cropperSettings" class="mb-12" ></img-cropper> -->
  <br />
  <span class="mb-12 result rounded" *ngIf="data.image">
    <img
      [src]="data.image"
      [width]="cropperSettings.croppedWidth"
      [height]="cropperSettings.croppedHeight"
    />
  </span>
</div>

<div mat-dialog-actions style="text-align: right">
  <span class="example-spacer" style="flex: 1"></span>
  <button mat-raised-button (click)="cancel()">Annulla</button>
  <button mat-raised-button color="accent" cdkFocusInitial (click)="confirm()">
    Conferma
  </button>
</div>
