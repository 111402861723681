import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { fuseAnimations } from "@fuse/animations";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { HomeService } from "./home.service";
import { AuthService, UserClass } from "app/services/auth.service";
import { HomeItem } from "./home-item";
import { AppointmentsListService } from "@shared/services";
import { DatabaseService, Appointment } from "@core/database";
import { map } from "rxjs/operators";

@Component({
  selector: "home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  widgets: any;
  messages: any;
  weather: any;
  user: UserClass;
  appointments$: Observable<HomeItem[]> = this._database.appointments
    .selectAll()
    .pipe(map((data) => this.convertListToHome(data)));

  // dateNow = Date.now();
  _dateNow$: BehaviorSubject<number> = new BehaviorSubject<number>(Date.now());
  dateNow$: Observable<number> = this._dateNow$.asObservable();

  // Private

  constructor(
    private _fuseSidebarService: FuseSidebarService,
    private _homeService: HomeService,
    private _database: DatabaseService,
    private _appointmentsService: AppointmentsListService,
    // private _weatherService: WeatherService,
    private _authService: AuthService
  ) {
    this.weather = false;
    this._unsubscribeAll = new Subject();

    this.widgets = false;
    setInterval(() => {
      // this.dateNow = Date.now();
      // this._dateNow$.next(Date.now());
    }, 1000);
  }

  ngOnInit(): void {
    this.user = this._authService.currentUserValue;
    this._homeService.fetchAppointment();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  /**
   * Toggle the sidebar
   *
   * @param name
   */
  toggleSidebar(name): void {
    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }

  convertListToHome(appointments: Appointment[]): HomeItem[] {
    const list: HomeItem[] = [];
    const tempList: any = {};
    for (const appointment of appointments) {
      const day = appointment.dtime.toString().split("T")[0];
      if (!tempList[day]) {
        tempList[day] = new HomeItem(day);
      }
      tempList[day].addAppointment(appointment);
    }

    for (const key of Object.keys(tempList)) {
      list.push(tempList[key]);
    }
    return list;
  }
}
