<form name="schedaForm" *ngIf="scheda$ | async as scheda" [formGroup]="formScheda" autocomplete="off" fxLayout="column"
  fxLayout.gt-sm="row wrap">  
  <automated-field *ngFor="let field of scheda.fields" formControlName="{{field.DataModel}}" [field]="field"
    [control] = "formScheda.controls[field.DataModel]"
    [form]="formScheda" fxFlex.gt-sm="50" fxFlex.gt-md="33"></automated-field>      
</form>

<div fxLayout="row" fxLayoutAlign="center center" class="w-100-p">
  <button mat-raised-button color="accent" (click)="confirmForm()">
    <mat-icon class="mr-4">check</mat-icon>
    Conferma ed invia
  </button>
</div>