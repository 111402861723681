import { Injectable } from "@angular/core";
import { Observable, Subscriber } from "rxjs";
import { AppointmentsTableService } from "../tables";
import { Appointment } from "../models";

import { AppointmentsListService } from "@shared/services";
import { SettingsService } from "@shared/services/settings.service";
import { Settings } from "@shared/models";
import { FluidiTableService } from "../tables/fluidi.table.service";
import { CombustibiliTableService } from "../tables/combustibili.table.service";
import { SchedeTableService } from "../tables/schede.table.service";
import { StatiTableService } from "../tables/stati.table.service";

@Injectable({
  providedIn: "root",
})
export class DatabaseAjaxImporterService {
  private observer: Subscriber<any>;
  private operationCompleted = 0;
  private numberOperationToComplete = 2;

  constructor(
    public appointments: AppointmentsTableService,
    public fluidi: FluidiTableService,
    public combustibili: CombustibiliTableService,
    public schede: SchedeTableService,
    public stati: StatiTableService,
    private appointmentList: AppointmentsListService,
    private settingsService: SettingsService
  ) {
    this.appointmentList.appointments$.subscribe((appoints) =>
      this.importAppuntamenti(appoints)
    );

    this.settingsService.settings$.subscribe((settings) =>
      this.importSettings(settings)
    );
  }

  importFromAjax(): Observable<void> {
    return new Observable((observer) => {
      this.operationCompleted = 0;
      this.observer = observer;
      this.appointmentList.update();
      this.settingsService.update();
    });
  }

  private incrementCompleted(): void {
    this.operationCompleted++;
    if (this.operationCompleted >= this.numberOperationToComplete) {
      this.observer.next();
      this.observer.complete();
    }
  }

  private async importAppuntamenti(appointments: Appointment[]): Promise<void> {
    await this.appointments.truncate(false).toPromise();
    if (appointments) {
      await this.appointments
        .insertBulk(
          appointments.map((app) => {
            app.dtime = app.dtime.toString().replace(" ", "T");
            return app;
          })
        )
        .toPromise();
      this.incrementCompleted();
    }
  }

  private async importSettings(settings: Settings): Promise<void> {
    if (!settings) {
      return;
    }
    // import schede
    if (settings.schede) {
      await this.schede.truncate(false).toPromise();
      await this.schede.insertBulk(settings.schede).toPromise();
    }

    for (const item of settings.Enum) {
      switch (item.name) {
        case "EnumStati_ispezione":
          await this.stati.truncate(false).toPromise();
          await this.stati.insertBulk(item.items).toPromise();
          break;
      }
    }

    this.incrementCompleted();
  }
}
