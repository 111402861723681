<div id="error-404" fxLayout="column" fxLayoutAlign="center center">

  <div class="content" fxLayout="column" fxLayoutAlign="center center">

      <div class="error-code">{{ code }}</div>

      <div class="message">{{ message }}</div>
      

      <a class="back-link" [routerLink]="'/home'">Torna alla home</a>

  </div>

</div>