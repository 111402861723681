<div id="profile" class="page-layout simple tabbed">
  <!-- HEADER -->
  <div
    class="header p-24"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayout.gt-md="row"
    fxLayoutAlign.gt-md="space-between end"
  >
    <div
      class="user-info"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayout.gt-md="row"
      fxLayoutAlign.gt-md="start center"
    >
      <img
        class="profile-image avatar huge"
        (click)="changeAvatar()"
        [src]="profile.avatar"
        [@animate]="{ value: '*', params: { delay: '50ms', scale: '0.2' } }"
      />
      <div
        class="name"
        [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }"
      >
        {{ profile.nome }} {{ profile.cognome }}
      </div>
    </div>

    <div
      class="actions"
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutAlign.lt-md=" center center "
      [@animate]="{ value: '*', params: { delay: '200ms' } }"
      *ngIf="!inEdit"
    >
      <button
        mat-raised-button
        color="accent"
        aria-label="Edit"
        (click)="launchEdit()"
      >
        <mat-icon style="margin-right: 5px">edit</mat-icon> Modifica
      </button>
      <!-- <button mat-raised-button color="primary" aria-label="Send Message">Send Message</button> -->
    </div>

    <div
      class="actions"
      fxLayout="row"
      fxLayoutAlign="end center"
      [@animate]="{ value: '*', params: { delay: '200ms' } }"
      *ngIf="inEdit"
    >
      <button
        mat-raised-button
        color="accent"
        aria-label="Salva"
        (click)="save()"
      >
        <mat-icon style="margin-right: 5px">save</mat-icon> Salva
      </button>
      <button
        class="ml-8"
        mat-raised-button
        color="primary"
        aria-label="Annulla"
        (click)="closeEdit()"
      >
        <mat-icon style="margin-right: 5px">cancel</mat-icon>Annulla
      </button>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content">
    <form name="profileForm" [formGroup]="profileForm" novalidate>
      <div id="about" class="p-24" fxLayout.gt-md="row wrap" fxLayout="column">
        <div
          class="about-content"
          fxLayout="column"
          fxLayout.gt-md="row"
          fxFlex="100"
        >
          <div
            class="profile-box info-box general"
            fxLayout="column"
            fxFlex.gt-md="50"
            fxFlex="0"
          >
            <header class="accent">
              <div class="title">General Information</div>
            </header>

            <div class="content" *ngIf="!inEdit">
              <div class="info-line">
                <div class="title">Username</div>
                <div class="info">{{ profile.login }}</div>
              </div>
              <div class="info-line">
                <div class="title">Nome</div>
                <div class="info">{{ profile.nome }}</div>
              </div>

              <div class="info-line">
                <div class="title">Cognome</div>
                <div class="info">{{ profile.cognome }}</div>
              </div>

              <div class="info-line">
                <div class="title">Sesso</div>
                <div class="info" *ngIf="profile.sesso == 0">Maschile</div>
                <div class="info" *ngIf="profile.sesso == 1">Femminile</div>
              </div>

              <div class="info-line">
                <div class="title">Data di nascita</div>
                <div class="info">
                  {{ profile.data_nascita | date: "d MMM yyyy" }}
                </div>
              </div>
            </div>

            <div class="content" *ngIf="inEdit">
              <div class="info-line">
                <div class="info-line">
                  <div class="title">Username</div>
                  <div class="info">{{ profile.login }}</div>
                </div>
              </div>
              <div class="info-line">
                <mat-form-field appearance="outline" fxFill>
                  <mat-label>Nome</mat-label>
                  <input matInput formControlName="nome" />
                  <mat-error
                    *ngIf="profileForm.get('nome').hasError('required')"
                  >
                    Il nome è obbligatorio
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="info-line">
                <mat-form-field appearance="outline" fxFill>
                  <mat-label>cognome</mat-label>
                  <input matInput formControlName="cognome" />
                  <mat-error
                    *ngIf="profileForm.get('cognome').hasError('required')"
                  >
                    Il nome è obbligatorio
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="info-line">
                <mat-form-field appearance="outline" fxFill>
                  <mat-label>Sesso</mat-label>
                  <mat-select formControlName="sesso">
                    <mat-option value="0">Maschile</mat-option>
                    <mat-option value="1">Femminile</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="info-line">
                <mat-form-field appearance="outline" fxFill>
                  <mat-label>Data di nascita</mat-label>
                  <input
                    matInput
                    [matDatepicker]="bDdayDatePicker"
                    name="data_nascita"
                    formControlName="data_nascita"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="bDdayDatePicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker
                    touchUi
                    #bDdayDatePicker
                    disabled="false"
                  ></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div
            class="profile-box info-box contact"
            fxLayout="column"
            fxFlex.gt-md="50"
            fxFlex="0"
          >
            <header class="accent">
              <div class="title">Contatti</div>
            </header>

            <div class="content" *ngIf="!inEdit">
              <div class="info-line">
                <div class="title">Telefono</div>
                <div class="info">{{ profile.mobile }}</div>
              </div>

              <div class="info-line">
                <div class="title">email</div>
                <div class="info">{{ profile.email }}</div>
              </div>
              <div class="info-line">
                <div class="title">Indirizzo</div>
                <div class="info">{{ profile.formatted_sede }}</div>
              </div>
            </div>
            <div class="content" *ngIf="inEdit">
              <div class="info-line">
                <mat-form-field appearance="outline" id="mobile" fxFill>
                  <mat-label>Cellulare</mat-label>
                  <input
                    matInput
                    formControlName="mobile"
                    ng2TelInput
                    [ng2TelInputOptions]="{ initialCountry: 'it' }"
                    (intlTelInputObject)="telInputObject($event)"
                    (ng2TelOutput)="getNumber($event)"
                  />
                  <mat-icon matSuffix class="secondary-text">phone</mat-icon>
                  <mat-error
                    *ngIf="profileForm.get('mobile').hasError('required')"
                  >
                    Il cellulare ò obbligatorio
                  </mat-error>
                  <mat-error
                    *ngIf="profileForm.get('mobile').hasError('numberNotValid')"
                  >
                    Il numero inserito non è nel formato corretto
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="info-line">
                <mat-form-field appearance="outline" fxFill>
                  <mat-label>Email</mat-label>
                  <input matInput formControlName="email" />
                  <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                  <mat-error
                    *ngIf="profileForm.get('email').hasError('required')"
                  >
                    L'email è obbligatoria
                  </mat-error>
                  <mat-error *ngIf="profileForm.get('email').hasError('email')">
                    Inserisci una email valida
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="info-line">
                <mat-form-field appearance="outline" fxFill>
                  <mat-label>Inidirizzo</mat-label>
                  <!-- <input matInput formControlName="sede"    
                                          matGoogleMapsAutocomplete                                                                                  
                                         (change)="onChangeAddress($event)"
                                         [country]="[]"
                                         [types]="[]"                                          
                                         (onAutocompleteSelected)="onAutocompleteSelected($event)"
                                         (onLocationSelected)="onLocationSelected($event)"> -->
                  <mat-icon matSuffix class="secondary-text">business</mat-icon>
                  <mat-error
                    *ngIf="profileForm.get('sede').hasError('validateAddress')"
                  >
                    indirizzo non valido
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- / CONTENT -->
</div>
