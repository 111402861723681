import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiFileUploaderComponent } from './multi-file-uploader.component';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  declarations: [MultiFileUploaderComponent],
  imports: [
    CommonModule,

    MatProgressBarModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,

    FuseSharedModule
    
  ],
  exports: [MultiFileUploaderComponent]
})
export class MultiFileUploaderModule { }
