import { NgModule, ChangeDetectorRef } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ModaliService } from "./modali-service.service";
import { ChangePasswordComponent } from "./change-password/change-password.component";

import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { FuseSharedModule } from "../../@fuse/shared.module";
import { TermsConditionComponent } from "./terms-condition/terms-condition.component";
import { ConfirmComponent } from "./confirm/confirm.component";
import { ChangeAvatarComponent } from "./change-avatar/change-avatar.component";
// import { ImageCropperModule} from 'ngx-img-cropper';

@NgModule({
    declarations: [
        ChangePasswordComponent,
        TermsConditionComponent,
        ConfirmComponent,
        ChangeAvatarComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatDialogModule,
        FuseSharedModule,
        // ImageCropperModule,
    ],
    providers: [ModaliService]
})
export class ModaliModule {}
