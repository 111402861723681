import { Generatore } from './generatore.model';

export interface Impianto {
    targa:           string;
    idcombustibile:  number;
    attivo:          number;
    idDistribuzione: number;
    denominazione:   string;
    indirizzo:       string;
    palazzo:         string;
    interno:         string;
    pdr:             string;
    municipio:       string;
    idloc:           number;
    generatori:      Generatore[];
}
