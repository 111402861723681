import { Injectable } from '@angular/core';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { AuthService } from './auth.service';
import { Subject } from 'rxjs';
import { environment } from 'environments/environment';

declare let config: any;


export class WebSocketMessage{
  cmd: string;
  data: any;
  zipped: boolean;

  constructor(cmd: string) {
    this.cmd = cmd;
  }

}

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {



  private _subscribers: Map<string, Subject<any>> = new Map<string, Subject<any>>(); 
  private urn = environment.websocket.protocol + environment.websocket.server + ':' + environment.websocket.port;

  obsWebsocket: WebSocketSubject<any>;  
  constructor(
    private authService: AuthService
  ) { 
    this.init();
  }

  init(): void {
    
    
    // this._connect();
  }

  _connect(): void {
    this.obsWebsocket = webSocket(this.urn);
    // console.log(this.obsWebsocket);
    this.obsWebsocket    
    .subscribe(
        this._receiveMessage.bind(this),
        error => {          
          if (error.currentTarget.readyState) {          
            if (error.currentTarget.readyState === 3) {          
              setTimeout(() => this._connect(), 5000);
            }  
          }
          console.log('error on websocket', error);
        },
        () => {}
    );
    this.send('auth', environment.websocket.svkwb);
  }

  _receiveMessage( message ): void{        
    this.emit(message.cmd, message.data );  
  }

  send(cmd: string, data?: any): void {
    const msg = new WebSocketMessage(cmd);
    if (data) { msg.data = data; }
    this.obsWebsocket.next(msg);
  }

  _unzip(buffer): string{
    const compressData = atob(buffer);
    // const compressDataArray = compressData.split('').map(function(e) {
    //         return e.charCodeAt(0);
    // });
    // const inflate = zlib.inflateSync(compressData);
    // const output = inflate.toString();
    return '';
    // return wsclient.decodeUtf8(output);
  }

  emit(eventName: string, data: any): void {
    if (!this._subscribers.has(eventName)) { return ; }
    this._subscribers.get(eventName).next(data);
  }

  register(eventName: string): Subject<any> {

    
    if (!this._subscribers.has(eventName)) {
      const subJect: Subject<any> = new Subject<any>();
      this._subscribers.set(eventName, subJect);
    }
    return this._subscribers.get(eventName);

  }

  _next(eventName: string, data?: any): void{
    if (!this._subscribers.has(eventName)) { return ; }
    this._subscribers.get(eventName).next(data);
  }
  

}
