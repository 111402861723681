
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { DatabaseService } from "@core/database";
import { ApiService } from 'app/services/api.service';
import { LoaderService } from "app/services/loader.service";
import { strictEqual } from "assert";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

const currentVersion = 1;




@Injectable({
  providedIn: "root",
})
export class NewImplantService {
  constructor(private databaseService: DatabaseService, private api: ApiService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.databaseService.restore().pipe(map(() => true));
  }

  async save(formResult: any): Promise<any> {
    
    this.api.send( "/newimplant",formResult).subscribe((data) => { 
        
    });
  
  }
}
