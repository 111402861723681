<div id="register" fxLayout="column">

    <div id="register-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="register-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="/dist/assets/images/logos/fuse.svg">
            </div>

            <div class="title">CREA UN ACCOUNT</div>

            <form name="registerForm" [formGroup]="registerForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Nome</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon matSuffix class="secondary-text">recent_actors</mat-icon>
                    <mat-error>
                        Il nome è obbligatorio
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Cognome</mat-label>
                    <input matInput formControlName="surname">
                    <mat-icon matSuffix class="secondary-text">recent_actors</mat-icon>
                    <mat-error>
                        Il nome è obbligatorio
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" id="mobile">
                    <mat-label>Cellulare</mat-label>
                    <input matInput formControlName="mobile" ng2TelInput
                           [ng2TelInputOptions]="{initialCountry: 'it'}"  (intlTelInputObject)="telInputObject($event)">
                    <mat-icon matSuffix class="secondary-text">phone</mat-icon>
                    <mat-error *ngIf="registerForm.get('mobile').hasError('required')">
                        Il cellulare ò obbligatorio
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('mobile').hasError('numberNotValid')">
                        Il numero inserito non è nel formato corretto
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="registerForm.get('email').hasError('required')">
                        L'email è obbligatoria
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('email').hasError('email')">
                        Inserisci una email valida
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        La password è obbligatoria
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password (Confirm)</mat-label>
                    <input matInput type="password" formControlName="passwordConfirm">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="registerForm.get('passwordConfirm').hasError('required')">
                        La password di conferma è obbligatoria
                    </mat-error>
                    <mat-error *ngIf="!registerForm.get('passwordConfirm').hasError('required') &&
                                       registerForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                        Le password non corrispondono
                    </mat-error>
                </mat-form-field>

                <div class="terms" fxLayout="row" fxLayoutAlign="center center">
                    <mat-checkbox name="terms" aria-label="I read and accept" formControlName="term" required>
                        <span>Ho letto ed accetto i </span>
                    </mat-checkbox>
                    <a href="javascript:" (click)="openTerms()">termini e condizioni</a>
                </div>

                <button mat-raised-button color="accent" class="submit-button" aria-label="CREA UN ACCOUNT" [disabled]="registerForm.invalid" (click)="sendRequest()">
                    CREA UN ACCOUNT
                </button>

            </form>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Hai già un account?</span>
                <a class="link" [routerLink]="'/login'">Login</a>
            </div>

        </div>

    </div>

</div>