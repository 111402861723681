import { Observable, Subscriber } from "rxjs";

import { BaseComponent } from "../base.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
declare var CallBack: any;

export interface ModaleModel {
  id: string;
  open(params?: any): Observable<any>;
}

export class ModaleClass extends BaseComponent {
  box: any;
  observer: Subscriber<any>;
  dialogRef: MatDialogRef<any>;

  constructor(public dialog: MatDialog) {
    super();
  }

  openBox(component: any, config?: any): MatDialogRef<any, any> {
    // $(this.box).fadeIn();
    return (this.dialogRef = this.dialog.open(component, config));
  }

  closeBox(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.closeBox();
    this.observer.complete();
  }
}
