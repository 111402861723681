export interface Scheda {
    idscheda:    number;
    id_workflow: string;
    titolo:      string;
    fields:      Field[];
}

export interface Field {
    label:             string;
    type_presentation: string;
    type:              Type;
    validators?:       Validator[];
    properties:        Property[];
    DataModel:         string;
    position:          number;
}

export interface Property {
    type:  string;
    value: string;
}

export interface Type {
    name:   string;
    value?: string;
}

export interface Validator {
    type: string;
}
