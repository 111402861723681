import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';

export const KeyStorage = 'weather_data';



@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  currentInfo: any;
  currentLocation: any;
  onCurrentInfoChange: BehaviorSubject<any>;
  private _cachedData: any;

  constructor(
    private api: ApiService
  ) {
    this.currentLocation = null;
    this.currentInfo = false;
    this._cachedData = null;
    this.onCurrentInfoChange = new BehaviorSubject(this.currentInfo);
    this.fetchFromCache();
    navigator.geolocation.watchPosition(
      coordinate => {
        this.currentLocation = coordinate.coords;
        this.fetchInfo();
      }
    );
  }


  fetchFromCache(): void {
      const dataCache = localStorage.getItem(KeyStorage);
      if (dataCache === null) {
        return ; 
      }
      this._cachedData = JSON.parse(dataCache);
      this.onCurrentInfoChange.next(this._cachedData.data);
      this.currentInfo = this._cachedData.data;
  }

  storeInCache(data: any): void {
      this._cachedData = {
        lat: this.currentLocation.latitude,
        lon: this.currentLocation.longitude,
        data: this.currentInfo,
        valid: data.valid
      };
      localStorage.setItem(KeyStorage, JSON.stringify(this._cachedData));

  }

  fetchInfo(): void {
    if (this._cachedData) {
      if (this._cachedData.lat === this.currentLocation.latitude &&
          this._cachedData.lon === this.currentLocation.longitude ) {
            const now = Math.trunc( new Date().getTime() / 1000 );
            if (now < this._cachedData.valid) {
              // console.log('valid weather data found in storage');
              return ;
            }
      }
    }
    this.api.sendEsito('/weather/action/updateinfobycoords', 
    {lat: this.currentLocation.latitude, lon: this.currentLocation.longitude })
    .toPromise().then(
      (resp: any) => {        
        resp.current.meteocons = this.convertIcon(resp.current.condition.condition.icon);
        resp.forecasts.map( forecast => {
            forecast.meteocons = this.convertIcon(forecast.condition.condition.icon);
            return forecast;
        });
        this.currentInfo = {
          current: resp.current,
          forecasts: resp.forecasts
        };        
        this.onCurrentInfoChange.next(this.currentInfo);
        this.storeInCache(resp);
      },
      (error: any) => {
        console.log('error on fetch info wheater', error);
      }
    );
  }  


  convertIcon(icon: string): string{
    switch (icon) {
      case '01d':
        return 'icon-sun';
      case '02d':       
        return 'icon-cloudy';        
      case '03d': 
        return 'icon-cloud2'; 
      case '04d':   
        return 'icon-cloudy4'; 
      case '09d': 
        return 'icon-rainy2'; 
      case '10d': 
        return 'icon-rainy'; 
      case '13d': 
        return 'icon-snowy3';
      case '50d': 
        return 'icon-weather';
      case '01n':
        return 'icon-moon';
      case '02n':       
        return 'icon-cloud';        
      case '03n': 
        return 'icon-cloud'; 
      case '04n':   
        return 'icon-cloudy4'; 
      case '09n': 
        return 'icon-rainy2'; 
      case '10n': 
        return 'icon-rainy'; 
      case '13n': 
        return 'icon-snowy3';
      case '50n': 
        return 'icon-weather2';  
      default:
        return 'icon-lines';  
      






    }
    // $icodes = array('01d' => $this->_('Clear sky'),
    //                         '02d' => $this->_('Few clouds'),
    //                         '03d' => $this->_('Scattered clouds'),
    //                         '04d' => $this->_('Broken clouds'),
    //                         '09d' => $this->_('Shower rain'),
    //                         '10d' => $this->_('Rain'),
    //                         '11d' => $this->_('Thunderstorm'),
    //                         '13d' => $this->_('Snow'),
    //                         '50d' => $this->_('Mist'),
    //                         '01n' => $this->_('Clear sky, Night'),
    //                         '02n' => $this->_('Few clouds, Night'),
    //                         '03n' => $this->_('Scattered clouds, Night'),
    //                         '04n' => $this->_('Broken clouds, Night'),
    //                         '09n' => $this->_('Shower rain, Night'),
    //                         '10n' => $this->_('Rain, Night'),
    //                         '11n' => $this->_('Thunderstorm, Night'),
    //                         '13n' => $this->_('Snow, Night'),
    //                         '50n' => $this->_('Mist, Night'));
  }

}
