
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';

import { Observable } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { Scheda, DatabaseService, Field, Validator } from '@core/database';
import { SharedModule } from '@shared/shared.module';
import {DatabaseAjaxImporterService} from '../core/database/importer/database-ajax-importer.service';
import { AutomatedFormModule } from '../shared/automated-form/automated-form.module';
import { environment } from "../../environments/environment";
import { NewImplantService } from './newimplant.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newimplant',
  templateUrl: './newimplant.component.html',
  styleUrls: ['./newimplant.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})


export class NewimplantComponent implements OnInit {

  
  
  

  constructor(private _service : NewImplantService,private router : Router,private _db : DatabaseAjaxImporterService) { 

  }

  ngOnInit() {
    
  }

  confirmed(values: any): void {
    console.log('form values', values);
    
    this._service
      .save(values)
      .then(() =>
        {
          this._db.importFromAjax();
          this.router.navigate([
            '/home'
          ]);
        }
      );
  }

}
