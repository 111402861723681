import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {AuthService} from '../services/auth.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthService
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    
        const currentUser = this.authenticationService.currentUserValue;
        
        if (currentUser) {

            if (state.url.indexOf('/login') !== -1) {
                  this.router.navigate(['/']);
                  return false;
            }

            switch ( state.url ) {
                case '/reset-password':
                case '/register':
                // case '/mail-c':
                case '/forgot-password':
                    return false;
                default:
                    return true;
            }
            // logged in so return true
            // return true;
        }

        if (state.url.indexOf('/login') !== -1 ) {
            return true;
        }

        switch ( state.url ) {
            case '/reset-password':
            case '/register':
            // case '/mail-c':
            case '/forgot-password':
                return true;
        }


        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
        return false;
    }
}
