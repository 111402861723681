import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  
  private _code: number;
  private _message: string;
  constructor(
    private router: Router
  ) { }

  notFound(): void {
    this._code = 404;
    this._message = 'la pagina che cercavi non è stata trovata';
    this._goToError();    
  }

  showError(message: string, code?: number): void {
    if (code) { this.code = code; }
    else  { this.code = 500; }
    this.message = message;
    this._goToError();
  } 

  private _goToError(): void{
    this.router.navigate(['/error']);
  }

  /**
   * getters and setters
   */

  get code(): number{
    return this._code;
  }

  set code(code: number){
    this._code = code;
  }
  
  get message(): string{
    return this._message;
  }
  
  set message(message: string){
    this._message = message;
  }

}
