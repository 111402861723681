<div fxLayout="row" class="home-message-item" matRipple fxLayoutAlign="start center">

    <!-- <mat-checkbox [(ngModel)]="selected" (ngModelChange)="onSelectedChange()"
                    (click)="$event.stopPropagation();"></mat-checkbox> -->

    <div class="info" fxFlex FlexLayout="column">

        <div class="row-1" fxLayout="row" fxLayoutAlign="start center">

            <div class="name" fxLayout="row" fxLayoutAlign="start center" fxFlex>   
                <div class="avatar accent" >{{ message.mitt_name[0] }} </div>                                                                                                                                                                                                 
                <span class="text-truncate" >{{ message.mitt_name }} </span>                                                                
            </div>

            <div fxLayout="row" fxLayoutAlign="start center">                                                
                <div class="time" *ngIf="message.ago!=''">{{ message.ago }}</div>                                                
                <div class="time" *ngIf="message.ago==''">{{ message.data | date:'d MMMM' }}</div>                                                
            </div>

        </div>

        <div class="row-2" fxLayout="row" fxLayoutAlign="start center">

            <div fxLayout="column" fxLayoutAlign="start start">

                <div class="subject text-truncate">
                    {{ message.titolo }}
                </div> 

                <div class="message text-truncate" >
                    {{message.corpo | htmlToPlaintext | slice:0:180}}
                </div>

                <!-- <div class="labels" fxLayout="row wrap" fxHide fxShow.gt-sm>
                    <div class="label" *ngFor="let labelId of mail.labels"
                            fxLayout="row" fxLayoutAlign="start center">
                        <div class="label-color"
                                [ngStyle]="{'background-color': labels | getById:labelId:'color'}"></div>
                        <div class="label-title">{{labels | getById:labelId:'title'}}</div>
                    </div>
                </div> -->

            </div>

        </div>

    </div>

</div>
