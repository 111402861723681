import { Injectable, NgZone } from "@angular/core";
import { AppointmentsTableService } from "./tables";
import { Observable } from "rxjs";
import { DatabaseStoreService } from "./database.store.service";
import { CombustibiliTableService } from "./tables/combustibili.table.service";
import { FluidiTableService } from "./tables/fluidi.table.service";
import { SchedeTableService } from "./tables/schede.table.service";
import { StatiTableService } from "./tables/stati.table.service";
import { take } from "rxjs/operators";
declare let webWorkers: any;

@Injectable({
  providedIn: "root",
})
export class DatabaseService {
  constructor(
    public appointments: AppointmentsTableService,
    public combustibili: CombustibiliTableService,
    public fluidi: FluidiTableService,
    public schede: SchedeTableService,
    public stati: StatiTableService,
    private store: DatabaseStoreService,
    private _zone: NgZone
  ) {
    setTimeout(
      () =>
        this.appointments.changedData$.subscribe((_) =>
          this.store.saveInStore()
        ),
      1500
    );
    webWorkers.onMessage("ispezioni-upload", (event) => {
      console.log(event);
      if (!event.data.message) {
        return;
      }
      if (event.data.message === "ispezioni-updated") {
        this._zone.run(() => {
          this.restore(true).toPromise();
        });
      }
    });
  }

  // checkData(): Observable<void> {
  //   return this.store.checkData();
  // }

  restore(forceNetwork?: boolean): Observable<void> {
    return this.store.restore(forceNetwork);
  }
}
