// start:ng42.barrel
export * from './appointment.model';
export * from './appuntamento.model';
export * from './detail-appointment';
export * from './enum-item.model';
export * from './generatore.model';
export * from './impianto.model';
export * from './responsabile.model';
export * from './scheda.model';
export * from './select-item.model';
// end:ng42.barrel

