export class ObjectModel
{
      
    constructor(obj?: any)
    {
        
        if (obj) {            
            for (const field of Object.keys(obj)) {                                                
                this[field] = obj[field];
            }        
        }
    }
}