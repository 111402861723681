import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentsListService } from './services/appointments-list.service';
import { SettingsService } from './services/settings.service';
import { CombustibilePipe } from './pipes/combustibile.pipe';
import { AutomatedFormModule } from './automated-form/automated-form.module';
import { StatoPipe } from './pipes/stato.pipe';
import { IconStatoPipe } from './pipes/icon-stato.pipe';

@NgModule({
  declarations: [CombustibilePipe, StatoPipe, IconStatoPipe],
  imports: [CommonModule, AutomatedFormModule],
  providers: [AppointmentsListService, SettingsService],
  exports: [CombustibilePipe, AutomatedFormModule, StatoPipe, IconStatoPipe],
})
export class SharedModule {}
