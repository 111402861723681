import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { ApiService } from "./api.service";
import { environment } from "environments/environment";
declare let webWorkers: any;
declare let config: any;

enum KeyStorage {
  session = "currentSession",
  user = "currentUser",
  token = "token_pwa",
  tokenRefresh = "token_refresh",
  tokenExpire = "token_expire",
}

export interface UserClass {
  avatar?: string;
  description?: string;
  iduser?: string;
  name?: string;
  surname?: string;
  email?: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public onChangeCurrentUser: BehaviorSubject<UserClass>;
  public currentUser: Observable<UserClass>;
  private readonly _tokenLeaseMS = 864000;

  constructor(private api: ApiService) {
    this.onChangeCurrentUser = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem(KeyStorage.user))
    );
    this.currentUser = this.onChangeCurrentUser.asObservable();
  }

  public set currentUserValue(user: UserClass) {
    localStorage.setItem(KeyStorage.user, JSON.stringify(user));
    this.onChangeCurrentUser.next(user);
  }

  public get currentUserValue(): UserClass {
    return this.onChangeCurrentUser.value;
  }

  public get isLogged(): boolean {
    if (this.currentUserValue == null) {
      return false;
    }
    return true;
  }

  checkBootLogin(): Promise<any> {
    return new Promise((resolve, reject) => {
      // no data user found

      if (!this.currentUserValue) {
        resolve(0);
        return;
      }

      const token: string = localStorage.getItem(KeyStorage.token);
      const token_refresh = localStorage.getItem(KeyStorage.tokenRefresh);
      const token_expire = localStorage.getItem(KeyStorage.tokenExpire);

      if (!token || !token_refresh || !token_expire) {
        this._logout();
        resolve(0);
        return;
      }

      if (token_expire < new Date().getTime().toString()) {
        // token scaduto riprovo aggiornamento token
        this.refreshToken(resolve);
        return;
      }

      environment.api.token = token;
      environment.logged = true;
      webWorkers.sendMessageAll("token-auth", environment.api.token);
      resolve(0);
    });
  }

  refreshToken(resolve?: any): void {
    const token_refresh = localStorage.getItem(KeyStorage.tokenRefresh);
    environment.api.token = token_refresh;
    this.api.send("/refreshtoken", null, true).subscribe(
      (data) => {
        this.storeToken(data);
        this.updateTokenExpire();
        resolve(0);
      },
      () => resolve()
    );
  }

  storeLoginData(data: any, email: string): void {
    const names: string[] = data.username.split(" ");
    const user: UserClass = {
      description: data.username,
      surname: names[0],
      name: names[1] ? names[1] : "",
      email: email,
      avatar:
        "https://ui-avatars.com/api/?rounded=true&background=03a6e9&color=fff&name=" +
        data.username,
    };
    localStorage.setItem(KeyStorage.user, JSON.stringify(user));
    this.storeToken(data);
    environment.logged = true;
    this.onChangeCurrentUser.next(user);
  }

  private storeToken(data): void {
    localStorage.setItem(KeyStorage.token, data.token);
    localStorage.setItem(KeyStorage.tokenRefresh, data.token_refresh);
    environment.api.token = data.token;
    webWorkers.sendMessageAll("token-auth", environment.api.token);
  }

  private updateTokenExpire(): void {
    // aggiungo 24h alla scadenza del token
    localStorage.setItem(
      KeyStorage.tokenExpire,
      (new Date().getTime() + 86400000).toString()
    );
  }

  login(username: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api
        .send("/login", { email: username, password: password })
        .subscribe(
          (data: any) => {
            this.storeLoginData(data, username);
            this.updateTokenExpire();
            resolve(0);
          },
          (error: any) => {
            console.log("error", error);
            reject(error);
          }
        );
    });
  }

  _logout(): void {
    // remove user from local storage to log user out
    // localStorage.removeItem(KeyStorage.session);
    localStorage.removeItem(KeyStorage.user);
    localStorage.removeItem(KeyStorage.token);
    localStorage.removeItem(KeyStorage.tokenRefresh);
    localStorage.removeItem(KeyStorage.tokenExpire);

    this.onChangeCurrentUser.next(null);
  }

  logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._logout();
      document.location.href = "/login";
      resolve(0);
    });
  }

  changePassword(currentPassword: string, newPassword: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api
        .sendEsito("/login/action/changepassword", {
          currentPassword: currentPassword,
          newPassword: newPassword,
        })
        .toPromise()
        .then(
          (data: any) => {
            localStorage.setItem(KeyStorage.token, data.token);
            resolve(0);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  }
}
