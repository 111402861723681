export interface Appuntamento {
    idappuntamenti: number;
    idispettore:    number;
    iduser:         number;
    dtime:          Date;
    time:           string;
    note:           string;
    prog:           number;
    stato:          number;
    motivostato:    string;
    idimpianto:     number;
    lstupdate:      Date;
}
