import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'home-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations  
})
export class MessageItemComponent implements OnInit {
  @Input() message: Message;
  constructor() { }

  ngOnInit(): void  {
  }
  
}

export class Message {
  ago: string;
  approved: string;
  corpo: string;
  data: string;
  date_check: string;
  destinatario: string;
  extra: string;
  family: string;
  idmessaggio: string;
  letto: string;
  mitt_name: string;
  mittente: string;
  must_read: string;
  notificato: string;
  titolo: string;
  type: string;
  type_name: string;
}

