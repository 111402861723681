import { Injectable } from '@angular/core';
import { ModaleClass, ModaleModel } from '../modali-classes';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ChangeAvatarComponent } from './change-avatar.component';

@Injectable({
  providedIn: 'root'
})
export class ChangeAvatarService extends ModaleClass implements ModaleModel {



  public id = 'change-avatar';  

  constructor(public dialog: MatDialog ) {
    super(dialog);
  }

  open(): Observable<any> {
    return Observable.create((observer) => {      
      // this.init();            
      this.openBox(ChangeAvatarComponent, { width: 'auto' , data: { observer: observer}});      
      this.dialogRef.afterClosed().subscribe(
        () => {          
          observer.complete();
        }
      );      
    });
  }
}
