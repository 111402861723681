import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";

@Injectable()
export class ApiService {
  constructor(private http: HttpClient /*private upload: Upload */) {}

  public sendEsito(
    url: string,
    params?: any,
    silent: boolean = false,
    notShowError: boolean = false
  ): Observable<any> {
    return this.send(url, params, silent, notShowError);
  }

  public send(
    url: string,
    params?: any,
    silent: boolean = false,
    notShowError: boolean = false,
    resType: any = "json"
  ): Observable<any> {
    const http = this.http;
    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    if (silent) {
      headers["silent"] = true;
    }
    if (notShowError) {
      headers["notShowError"] = true;
    }
    
    return http.post(environment.api.url + url, params, {
      headers: headers,
      responseType: resType,
    });
  }

  public getContent(
    url: string,
    params: any,
    silent: boolean = false,
    notShowError: boolean = false
  ): Observable<any> {
    return this.send(url, params, silent, notShowError, "text");
  }

  setApiToken(token: string): void {}
}
