import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iconStato',
})
export class IconStatoPipe implements PipeTransform {
  transform(value: any, args?: any): string {
    switch (value) {
      case -1:
        return 'cloud_upload';
      case 1:
        return 'next_week';
      case 2: 
        return 'pause_circle_outline';
      case 3: 
        return 'not_interested';
      case 4:
        return 'check_circle';        
    }
  }
}
