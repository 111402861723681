import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-terms-condition',
    templateUrl: './terms-condition.component.html',
    styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<TermsConditionComponent>
    ) {
    }

    ngOnInit(): void {

    }

    close(): void {
        this.dialogRef.close();
    }

}
