import { Pipe, PipeTransform } from '@angular/core';
import { DatabaseService } from '@core/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'combustibile',
})
export class CombustibilePipe implements PipeTransform {
  constructor(private database: DatabaseService) {}

  transform(value: number, args?: any): Observable<string> {
    return this.database.combustibili
      .selectByPrimaryKey(value)
      .pipe(map((item) => (item ? item.label : '')));
  }
}
