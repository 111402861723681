import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { ProfileComponent } from "./profile.component";
import { AuthGuard } from "app/_guards/auth.guard";
import { ProfileService } from "./profile.service";
import { FuseSharedModule } from "@fuse/shared.module";
import { Ng2TelInputModule } from "ng2-tel-input";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
// import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
// import { MapsAPILoader, GoogleMapsAPIWrapper } from "@agm/core";

const routes: Routes = [
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [AuthGuard],
    resolve: {
      data: ProfileService,
    },
  },
];

@NgModule({
  declarations: [ProfileComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    FuseSharedModule,

    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,

    Ng2TelInputModule,
    // MatGoogleMapsAutocompleteModule,
  ],
  providers: [
    ProfileService,
    // GoogleMapsAPIWrapper],
  ],
})
export class ProfileModule {}
