import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ErrorService } from './error.service';

@Component({
  selector: 'error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ErrorComponent implements OnInit {

  code: number;
  message: string;

  constructor(
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.code = this.errorService.code;
    this.message = this.errorService.message;
  }

}
