<div class="page-layout simple right-sidebar" fxLayout="row" id="dashboard-project">
    <!-- CENTER -->
    <div class="center" fusePerfectScrollbar>
        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="space-between">
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <span class="mat-display-1 ml-12   welcome-message" [@animate]="{value:'*',params:{x:'50px'}}">Ben
                    tornato, {{ user.name }}!
                </span>
                <!-- <button (click)="toggleSidebar('project-dashboard-right-sidebar-1')" class="sidebar-toggle mr-8"
                    fxHide.gt-md mat-icon-button>
                    <mat-icon>menu</mat-icon>
                </button> -->
            </div>
        </div>
        <!-- / HEADER -->
        <!-- CONTENT -->
        <div class="content">
            <ng-container *ngIf="appointments$ | async as appointment">
                <div [@animateStagger]="{value:'50'}" class="widget-group p-12 pt-0" fxFlex="100" fxLayout="column">
                    <!-- WIDGET 1 -->
                    <h4 class="subtitle">Le tue ispezioni</h4>

                    <div *ngFor="let day of appointment" fxLayout="row">
                        <div class="date">
                            <div class="day"> {{day.dayNumber}}</div>
                            <div class="month"> {{day.month}}</div>
                            <div class="pointer"></div>
                        </div>
                        <div class="appuntamenti p-12" fxFlex=100>
                            <mat-card *ngFor="let appuntamento of day.appointments">
                                <div class="card-header" fxLayoutAlign="space-between center" fxLayout="row">
                                 <!--   <div>
                                        <mat-icon>query_builder</mat-icon> {{appuntamento.dtime | date:"HH:mm"}}
                                    </div> -->

                                    <div class="indirizzo" fxLayout="row" fxLayoutAlign="start center">
                                        <mat-icon>directions</mat-icon>
                                        {{ appuntamento.stato}}
                                    </div>
                                </div>
                                <mat-card-content class="p-12">
                                    <div class="indirizzo" fxLayout="row" fxLayoutAlign="start center">
                                        <mat-icon>directions</mat-icon>
                                        {{ appuntamento.indirizzo}}
                                    </div>
                                    <div class="responsabile" fxLayoutAlign="start center">
                                        <mat-icon>record_voice_over</mat-icon>
                                        {{ appuntamento.codice}} - {{ appuntamento.targa}} - {{ appuntamento.responsabile}}
                                    </div>
                                    <a [routerLink]="['/appointments/coming',appuntamento.idapp]">
                                        <mat-icon class="next-pointer">
                                            chevron_right
                                        </mat-icon>
                                    </a>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <!-- <h4>{{appuntamento.dateapp | date:"dd/MM/yy HH:mm"}}</h4>
                    <div>{{appuntamento.responsabile}} </div> -->
                    </div>
                </div>
            </ng-container>
            <!-- / CONTENT -->
        </div>
        <!-- / CENTER -->
        <!-- SIDEBAR -->

        <!-- / SIDEBAR -->
    </div>