import { Injectable } from '@angular/core';
import { BaseTable } from './base-table';
import { IndexDeclaration } from './indexing';
import { Scheda } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SchedeTableService extends BaseTable<Scheda> {
  initialize(): void {
    // insert here the initizialitation of table like indexes
    this.primaryIndex = new IndexDeclaration('primary', 'idscheda');    
  }
}
