<h1 mat-dialog-title>REIMPOSTA LA TUA PASSWORD </h1>
<div mat-dialog-content id="change-password" >
    <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate fxLayout="column" fxLayoutAlign="center center">

        <mat-form-field appearance="outline">
            <mat-label>Password attuale</mat-label>
            <input matInput type="password" formControlName="currentPassword">
            <mat-icon matSuffix class="secondary-text">perm_identity</mat-icon>
            <mat-error *ngIf="resetPasswordForm.get('currentPassword').hasError('required')">
                La password attuale è obbligatoria
            </mat-error>

        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput type="password" formControlName="password">
            <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
            <mat-error>
                Password è obbligatoria
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Password (Conferma)</mat-label>
            <input matInput type="password" formControlName="passwordConfirm">
            <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
            <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').hasError('required')">
                Password di conferma è obbligatoria
            </mat-error>
            <mat-error *ngIf="!resetPasswordForm.get('passwordConfirm').hasError('required') &&
                                       resetPasswordForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                Le password non corrispondono
            </mat-error>
        </mat-form-field>
    </form>
</div>

<div mat-dialog-actions style="text-align:right">
    <span class="example-spacer" style="flex:1"></span>
    <button mat-button (click)="cancel()">Annulla</button>
    <button mat-button cdkFocusInitial (click)="confirm()" [disabled]="resetPasswordForm.invalid" >Conferma</button>
</div>