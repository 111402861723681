export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'HELPDESK': 'Helpdesk',
            'HOME': {
                'TITLE': 'Home'
            },
            'PRODUCTS': {
                'TITLE': 'Products'
            },
            'INASSISTANCE': {
                'TITLE': 'In assistance',
                'PENDING': 'pending',
                'HISTORY': 'history'                
            },
            'SUPPORT': {
                'TITLE': 'Support'
            },
            'APPOINTMENTS': {
                'TITLE': 'Appointments'
            },
            'MESSAGES': {
                'TITLE': 'Messagges/notifies'
            },
            'SHOP_QUEUE': {
                'TITLE': 'Shop queue'
            }
        }
    }
};
