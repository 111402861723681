import { Injectable } from "@angular/core";
import { AppointmentsTableService } from "./tables";
import { finalize } from "rxjs/operators";
import { Observable, Observer } from "rxjs";
import { AuthService } from "app/services/auth.service";
import { DatabaseAjaxImporterService } from "./importer";
import { FluidiTableService } from "./tables/fluidi.table.service";
import { CombustibiliTableService } from "./tables/combustibili.table.service";
import { SchedeTableService } from "./tables/schede.table.service";
import { StatiTableService } from "./tables/stati.table.service";

@Injectable({
  providedIn: "root",
})
export class DatabaseStoreService {
  private readonly tables: string[] = [
    "appointments",
    "combustibili",
    "fluidi",
    "stati",
    "schede",
  ];

  private readonly daysImportExpire = 1;

  private readonly keysStore = {
    last_import: "db-last-import",
    db_data: "db-data",
  };

  constructor(
    public appointments: AppointmentsTableService,
    public fluidi: FluidiTableService,
    public combustibili: CombustibiliTableService,
    public schede: SchedeTableService,
    public stati: StatiTableService,
    private authService: AuthService,
    private importer: DatabaseAjaxImporterService
  ) {
    this.authService.currentUser.subscribe(() => {
      if (!this.authService.isLogged) {
        this.clearStore();
        return;
      }
      this.restore().subscribe();
    });
  }

  restore(forceNetwork?: boolean): Observable<void> {
    return new Observable((observer) => {
      console.log(this.needsToImport(), forceNetwork);
      if (this.needsToImport() || forceNetwork) {
        this.importer
          .importFromAjax()
          .pipe(
            finalize(() => {
              this.saveInStore();
              observer.next();
              observer.complete();
            })
          )
          .subscribe();
      } else {
        this.loadFromStore(observer);
        // import from store
      }
    });
  }

  importFromLocalStorage(): Observable<void> {
    return new Observable((observer) => {
      observer.next();
      observer.complete();
    });
  }

  storeToLocal(): Observable<void> {
    return new Observable((observer) => {
      this.saveInStore();
      observer.next();
      observer.complete();
    });
  }

  needsToImport(): boolean {
    const lastImportStore = localStorage.getItem(this.keysStore.last_import);

    if (lastImportStore == null) {
      return true;
    }

    const now = new Date().getTime();
    const lastImport = parseInt(lastImportStore, 10);
    return (
      Math.round((lastImport - now) / (1000 * 60 * 60 * 24)) >=
      this.daysImportExpire
    );
  }

  saveInStore(): void {
    const objectToStore: any = {};
    console.trace();
    console.log(this.tables);
    for (const table of this.tables) {
      objectToStore[table] = this[table];
    }
    console.log(objectToStore);

    localStorage.setItem(this.keysStore.db_data, JSON.stringify(objectToStore));
    localStorage.setItem(
      this.keysStore.last_import,
      new Date().getTime().toString()
    );
  }

  async loadFromStore(observer: Observer<void>): Promise<void> {
    const storeString = localStorage.getItem(this.keysStore.db_data);
    if (storeString === null) {
      this.importer
        .importFromAjax()
        .pipe(
          finalize(() => {
            this.saveInStore();
            observer.next();
            observer.complete();
          })
        )
        .subscribe();
      return;
    }

    const storeRaw = JSON.parse(storeString);
    for (const table of this.tables) {
      if (!storeRaw[table]) {
        continue;
      }
      await this[table].loadData(storeRaw[table]);
    }
    observer.next();
    observer.complete();
  }

  clearStore(): void {
    localStorage.removeItem(this.keysStore.db_data);
    localStorage.removeItem(this.keysStore.last_import);
  }
}
