export class IndexFunctions {
  public static readonly keySeparator = '||';

  public static generateKeyByValue(...value: any[]): string {
    return value.map((val) => val.toString()).join(IndexFunctions.keySeparator);
  }

  public static getValue(key: string, object: any): string {
    if (key.indexOf('.') === -1) {
      return this.convertValue(object[key]);
    }

    const keys = key.split('.');
    // è una subproperty
    let value: any = object;
    for (const subKey of keys) {
      value = value[subKey];
      if (value === null)  {
        return 'null';
      }
      if (value === undefined) {
        return 'undefined';
      }
    }
    return this.convertValue(value);
  }

  public static *getValueArray(key: string, object: any) {
    if (key.indexOf('.') === -1) {
      for (const val of object[key]) {
        yield this.convertValue(val);
      }
      return;
    }
  }

  private static convertValue(value: any): string {
    if (value == null) {
      return 'null';
    }
    if (value === undefined) {
      return 'undefined';
    }
    return value.toString();
  }
}
