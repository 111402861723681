import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutomatedFieldComponent } from './automated-field/automated-field.component';
import { AutomatedFormComponent } from './automated-form/automated-form.component';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  declarations: [AutomatedFieldComponent, AutomatedFormComponent],
  imports: [
    CommonModule,

    FormsModule,

    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,

    FuseSharedModule,
  ],
  exports:[AutomatedFormComponent]
})
export class AutomatedFormModule {}
