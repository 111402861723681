import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, BehaviorSubject } from "rxjs";
import { ApiService } from "app/services/api.service";
import { NavigationNotificationService } from "app/services/navigation-notification.service";
import { HomeItem } from "./home-item";
import { AppointmentsListService } from "@shared/services";
import { map } from "rxjs/operators";
import { Appointment } from "@shared/models";
import { DatabaseService } from "@core/database";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  widgets: any;
  messages: any;

  // onChangeWidget: BehaviorSubject<any>;
  // onChangeMessages: BehaviorSubject<any>;

  appointments$: Observable<HomeItem[]> = this._database.appointments
    .selectAll()
    .pipe(map((data) => this.convertListToHome(data)));

  constructor(
    private appointmentListService: AppointmentsListService,
    private _navigationNotificationService: NavigationNotificationService,
    private _database: DatabaseService
  ) {
    // this.onChangeWidget = new BehaviorSubject({});
    // this.onChangeMessages = new BehaviorSubject({});
    // this._navigationNotificationService.onDataChanged.subscribe(
    //   (widget: any) => {
    //     this.onChangeWidget.next(widget);
    //   }
    // );
  }

  /**
   * Resolver for routing
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        // this.getWidget(),
        // this._navigationNotificationService.update(),
        // this.getLastmessage(),
      ]).then(() => {
        resolve(0);
      }, reject);
    });
  }

  getWidget(): Promise<any> {
    return new Promise((resolve) => {
      // this.api.sendEsito('/home/action/getwidgets').toPromise().then(
      //     (data: any) => {
      //         this.widgets = data;
      //         this.onChangeWidget.next(this.widgets);
      //     },
      //     (error: any) => {
      //         console.log('home widgets ', error);
      //     }
      // ).finally(() => resolve());
      resolve(0);
    });
  }

  getLastmessage(): Promise<any> {
    return new Promise((resolve, reject) => {
      // this.api.sendEsito('/messaggi/notifiche/action/getforhome').toPromise().then(
      //     (data: any) => {
      //         this.widgets = data;
      //         this.onChangeMessages.next(this.widgets);
      //     },
      //     (error: any) => {
      //         console.log('home widgets ', error);
      //     }
      // ).finally(() => resolve());
      resolve(0);
    });
  }

  fetchAppointment(): void {
    // this.appointmentListService.update();
  }

  convertListToHome(appointments: Appointment[]): HomeItem[] {
    const list: HomeItem[] = [];
    const tempList: any = {};
    for (const appointment of appointments) {
      const day = appointment.dtime.toString().split(" ")[0];
      if (!tempList[day]) {
        tempList[day] = new HomeItem(day);
      }
      tempList[day].addAppointment(appointment);
    }

    for (const key of Object.keys(tempList)) {
      list.push(tempList[key]);
    }
    return list;
  }
}
