<div fusePerfectScrollbar fxFlex fxLayout="column">
    <mat-list class="date" cdkFocusRegionStart>
        <h3 matSubheader cdkFocusInit>
            <span>Oggi</span>
        </h3>
        <div class="secondary-text mat-display-1 mb-0 p-16">
            <div class="mb-12">
                {{date | date:'EEEE' | titlecase}}
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
                <span>
                    {{date | date:'d'}}
                    &nbsp;
                </span>
                <!-- <span class="mat-subheading-1">th</span> -->
                <span>
                    {{date | date:'MMMM' | titlecase }}</span>
            </div>
        </div>
    </mat-list>
    <mat-divider cdkFocusRegionEnd></mat-divider>


    
</div>