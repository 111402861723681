import { Injectable } from "@angular/core";

import { ModaleModel } from "./modali-classes";
import { ChangePasswordService } from "./change-password/change-password.service";
import { TermsConditionService } from "./terms-condition/terms-condition.service";
import { ConfirmService } from "./confirm/confirm.service";
import { ChangeAvatarService } from "./change-avatar/change-avatar.service";
import { Observable } from "rxjs";
declare var CallBack: any;

@Injectable({
  providedIn: "root",
})
export class ModaliService {
  private modals: ModaleModel[] = [];

  constructor(
    private changePasswordService: ChangePasswordService,
    private termsCondistionService: TermsConditionService,
    private confirmService: ConfirmService,
    private changeAvatarService: ChangeAvatarService
  ) {
    this.add(this.changePasswordService);
    this.add(this.termsCondistionService);
    this.add(this.confirmService);
    this.add(this.changeAvatarService);
  }

  add(modale: ModaleModel): void {
    this.modals.push(modale);
  }

  openObservable(id: string, params?: any): Observable<any> {
    const modale: ModaleModel = this.modals.find((value) => value.id === id);
    if (modale === undefined) {
      return Observable.throw("Modale not found");
    }
    return modale.open(params);
  }

  open(id: string, params?: any): void {
    const modale: ModaleModel = this.modals.find((value) => value.id === id);
    console.log(this.modals);
    if (modale === undefined) {
      throw "Modale not found";
    }
    modale.open(params).subscribe();
  }
}
