import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  confirmText = 'Conferma operazione';
  cancelText = 'Chiudi';
  message = 'vuoi confermare?';
  title = 'Conferma';

  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {     
  }

  ngOnInit(): void {      
      if (this.data.options.message) { this.message = this.data.options.message; }
      if (this.data.options.confirmText) { this.confirmText = this.data.options.confirmText; }
      if (this.data.options.cancelText) { this.cancelText = this.data.options.cancelText; }
      if (this.data.options.title) { this.title = this.data.options.title; }
  }

  cancel(): void{
    this.dialogRef.close();
  }

  confirm(): void{
    this.data.observer.next();
    this.dialogRef.close();
  }


}
