<mat-form-field class="w-100-p">
  <mat-label>{{field.label}}</mat-label>
  <ng-container [ngSwitch]="model">
    <input #input *ngSwitchCase="'textbox'" matInput [(ngModel)]="innerValue" [type]="type" [readonly]="isReadOnly"
      [required]="isRequired" (blur)="onChange($event, input.value)">
    <textarea #input [readonly]="isReadOnly" matInput *ngSwitchCase="'textarea'" [(ngModel)]="innerValue"
      [required]="isRequired" (blur)="onChange($event, input.value)"></textarea>
    <mat-select #input *ngSwitchCase="'select'" [(ngModel)]="innerValue" [required]="isRequired"
      (blur)="onChange($event, input.value)">
      <mat-option *ngFor="let item of selectValues$ | async" [value]="item.value">
        {{item.display}}
      </mat-option>
    </mat-select>
  </ng-container>

  <ng-container *ngFor="let validator of validators">
    <ng-container [ngSwitch]='validator.type'>
      <ng-container *ngSwitchCase="'required'">
        <mat-error *ngIf="control.hasError('required')">
          Il campo è obbligatorio
        </mat-error>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-form-field>