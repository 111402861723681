import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
// import { ImageCropperComponent, CropperSettings } from "ngx-img-cropper";
import { NotificheService } from "app/services/notifiche.service";

@Component({
  selector: "change-avatar",
  templateUrl: "./change-avatar.component.html",
  styleUrls: ["./change-avatar.component.scss"],
})
export class ChangeAvatarComponent implements OnInit {
  @ViewChild("file") file;

  // @ViewChild("cropper")
  // cropper: ImageCropperComponent;
  data: any;
  // cropperSettings: CropperSettings;

  constructor(
    public dialogRef: MatDialogRef<ChangeAvatarComponent>,
    @Inject(MAT_DIALOG_DATA) public datiModale: any,
    private notifiche: NotificheService
  ) {
    // this.cropperSettings = new CropperSettings();
    // this.cropperSettings.noFileInput = true;
    // this.cropperSettings.rounded = true;
    this.data = {};
  }

  ngOnInit(): void {}

  addFiles($event): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.file.nativeElement.click();
  }

  fileChangeListener($event): void {
    const image: any = new Image();
    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();
    const that = this;
    myReader.onloadend = (loadEvent: any) => {
      // console.log(this);
      image.src = loadEvent.target.result;
      // that.cropper.setImage(image);
    };

    myReader.readAsDataURL(file);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    // if (!this.cropper.image.image) {
    //   this.notifiche.errore("Devi seleziona un'immagine");
    //   return;
    // }
    // this.datiModale.observer.next(this.cropper.image.image);
    // this.dialogRef.close();
  }
}
