<div id="forgot-password" fxLayout="column">

    <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="forgot-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="/dist/assets/images/logos/fuse.svg">
            </div>

            <div class="title">RESETTA LA TUA PASSWORD</div>

            <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
                        L'email è obbligatoria
                    </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
                        L'indirizzo email non è valido
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button class="submit-button" (click)="sendRequest()" color="accent" aria-label="SEND RESET LINK" [disabled]="forgotPasswordForm.invalid">
                    INVIA LINK RESET
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/pages/auth/login'">Go back to login</a>
            </div>

        </div>

    </div>

</div>