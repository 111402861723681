<div >
  <div class="attachment-list mb-36" >
    <div class="attachment" 
     [ngClass]="{error: attachment.value.error}" fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let attachment of attachments | keyvalue  ">
      <div>
        <span class="filename" matToolTip="attachment.value.name">
          {{attachment.value.name}}</span>
        <span class="size ml-4">({{attachment.value.sizeDisplay}})
        </span>
      </div>
      <div class="error p-24" *ngIf="attachment.value.error">{{ attachment.value.errorDescription }}</div>
      <mat-progress-bar class="mr-16" mode="determinate" value="{{attachment.value.progress}}" *ngIf="(!attachment.value.completed && !attachment.value.error)"></mat-progress-bar>
      <button  *ngIf="attachment.value.completed" aria-label="Delete attachment"
        mat-icon-button (click)="removeAttachment(attachment.key)">
        <mat-icon class="s-16">close</mat-icon>
      </button>
    </div>
  </div>
  <input #file (change)="onFilesAdded()" style="display: none" type="file" multiple />
  
  <button (click)="addFiles($event)"  aria-label="add attachment" class="add-attachment-button mb-36"
    mat-stroked-button [disabled]="inProgress">
    <mat-icon class="mr-8">attach_file</mat-icon> Aggiungi allegati
  </button>
  
</div>