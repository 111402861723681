<h1 mat-dialog-title>TERMINI E CONDIZIONI </h1>
<div  mat-dialog-content>
  <header>
    <h2>Terms of Use</h2>
  </header>
  <section>
    <h3>General Terms</h3>
    <p>By accessing and placing an order with AngularTemplates, you confirm that you are in agreement with and bound by the terms and conditions contained in the Terms Of Use outlined below. These terms apply to the entire website and any email or other type of communication between you and AngularTemplates.</p>
  </section>
  <section>
    <h3>Templates</h3>
    <p>All products and services are delivered by AngularTemplates electronically to your email address. AngularTemplates is not responsible for any technological delays beyond our control. If your spam blocker blocks our emails from reaching you or you do not provide a valid email address where you can be reachable then you can access your download from your Account page.</p>
  </section>
  <section>
    <h3>Licensing &amp; Usage</h3>
    <p>The use of an item is bound by the license you purchase. A license grants you a non-exclusive and non-transferable right to use and incorporate the template in your personal or commercial projects. There are two licenses available: Regular and Extended.</p>
    <h4>Regular License: Single Application License</h4>
    <ol>
      <li>Your use of the item is restricted to a single installation.</li>
      <li>The item may not be redistributed or resold.</li>
      <li>If the item contains licensed components, those components must only be used within the item and you must not extract and use them on a stand-alone basis.</li>
      <li>If the item was created using materials which are the subject of a GNU General Public License (GPL), your use of the item is subject to the terms of the GPL in place of the foregoing conditions (to the extent the GPL applies).</li>
    </ol>
    <h4>Extended License: Multiple Applications License</h4>
    <ol>
      <li>Your use of the item may extend to multiple installations.</li>
      <li>The item may not be redistributed or resold.</li>
      <li>If the item contains licensed components, those components must only be used within the item and you must not extract and use them on a stand-alone basis.</li>
      <li>If the item was created using materials which are the subject of a GNU General Public License (GPL), your use of the item is subject to the terms of the GPL in place of the foregoing conditions (to the extent the GPL applies).</li>
    </ol>
    <p>You are responsible to choose the appropriate license type. You are not allowed to redistribute these files in any way.</p>
  </section>
  <section>
    <h3>Security</h3>
    <p>AngularTemplates does not process any order payments through the website. All payments are processed securely through PayPal, a third party online payment provider.</p>
  </section>
  <section>
    <h3>Refunds</h3>
    <p>If the template does not meet with your expectations you can ask for a refund. In the event that you wish to receive a refund, AngularTemplates will issue you a refund and ask you to specify how the product failed to live up to expectations.</p>
  </section>
  <section>
    <h3>Support</h3>
    <p>AngularTemplates offers these templates and designs ‘as is’, with no implied meaning that they will function exactly as you wish or with all 3rd party components and plugins. Further, we offer no support via email or otherwise for installation, customization, administration of the templates on the operating system itself. We do, however offer support for errors and bugs pertaining to the templates. We are also happy to walk customers through the template structure and answer any support queries in that regard.</p>
  </section>
  <section>
    <h3>Ownership</h3>
    <p>You may not claim intellectual or exclusive ownership to any of our products, modified or unmodified. All products are property of AngularTemplates. Our products are provided “as is” without warranty of any kind, either expressed or implied. In no event shall our juridical person be liable for any damages including, but not limited to, direct, indirect, special, incidental or consequential damages or other losses arising out of the use of or inability to use our products.</p>
  </section>
  <section>
    <h3>Github</h3>
    <p>You cannot push the code of the template into a public github repositorie. If you need free private repositories you can use Bitbucket.</p>
  </section>
  <section>
    <h3>Price Changes</h3>
    <p>AngularTemplates reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, a subscription (or any part thereof) with or without notice. Prices of all subscriptions and products, including but not limited to monthly subscription plan fees, are subject to change upon 30 days notice from us. Such notice may be provided at any time by posting the changes to the AngularTemplates website.</p>
  </section>
  <section>
    <h3>Warranty</h3>
    <p>AngularTemplates does not warranty or guarantee these templates in any manner. We cannot guarantee they will function with all 3rd party components, plugins or operating systems. Compatibility should be tested against the demonstration templates on the demo operating system simulators. Please ensure that the operating systems you use will work with the templates as we can not guarantee that AngularTemplates templates will work with all operating systems. Our company reserves the right to change or modify current Terms and Conditions with no prior notice.</p>
  </section>
</div>
<div mat-dialog-actions style="text-align:right">
  <span class="example-spacer" style="flex:1"></span>
  <button mat-button cdkFocusInitial (click)="close()"  >CHIUDI</button>
</div>