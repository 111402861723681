<div id="newimplant" class="page-layout simple tabbed">
  <!-- HEADER -->
  <div class="header p-24" fxLayout="row" fxLayoutAlign="start center">
    <div class="user-info" fxLayout="row" fxLayoutAlign.gt-sm="start center">
      <button routerLink="/" backButton class="mr-16" class="button-back" mat-icon-button style="color: white">
        <mat-icon>arrow_back</mat-icon>
      </button>

      <div class="name" [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }">
        Nuovo Impianto
      </div>
    </div>

    <div class="actions" fxLayout="row" fxLayoutAlign="end center"
      [@animate]="{ value: '*', params: { delay: '200ms' } }">     
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content">
    <ng-container>      
      <div class="about-content" fxLayout="column">
        <div class="profile-box info-box general" fxLayout="column">
          <header class="accent" fxLayout="row" fxLayoutAlign="start center">
            <div class="title">
              <mat-icon>edit</mat-icon>Compila
            </div>
          </header>
          <div class="content">
            <automated-form [schedaId]="2" (confirmed)='confirmed($event)'>
            </automated-form>
          </div>
        </div>
      </div>
    </ng-container>

  </div>