export class IndexSearch{

    indexName: string;
    values: any[];
    // typeJoinConditions: TypeIndexJoinConditions;

    constructor(indexName: string, ...values: any[]){
        this.indexName = indexName;
        this.values = values;
    }

}

export enum TypeIndexJoinConditions{
    AND,
    OR
}