import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor( private loader: NgxSpinnerService) { }

  show(): void {
    this.loader.show();
  }

  hide(): void {
    this.loader.hide();
  }

}
