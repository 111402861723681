import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule } from "@ngx-translate/core";

import { FuseProgressBarModule, FuseSidebarModule } from "@fuse/components";
import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";

import { fuseConfig } from "app/fuse-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";

import { ServiceWorkerModule } from "@angular/service-worker";
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerModule } from "ngx-spinner";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { PagesModule } from "./main/pages/pages.module";
import { ApiService } from "./services/api.service";
import { AuthService } from "./services/auth.service";

// import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFireModule } from '@angular/fire';
import { MultiFileUploaderModule } from "./components/multi-file-uploader/multi-file-uploader.module";
import { HomeModule } from "./main/home/home.module";
import { ErrorService } from "./main/pages/error/error.service";
import { ModaliService } from "./modali/modali-service.service";
import { ModaliModule } from "./modali/modali.module";
import { BookingService } from "./services/booking.service";
import { LoaderService } from "./services/loader.service";
import { MessagingService } from "./services/messaging-service.service";
import { NavigationNotificationService } from "./services/navigation-notification.service";
import { UploaderService } from "./services/uploader.service";
import { WeatherService } from "./services/weather.service";
import { WebsocketService } from "./services/websocket.service";
// import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
// import { GoogleMapsAPIWrapper } from "@agm/core";
// import { AgmCoreModule } from "@agm/core";
import { registerLocaleData } from "@angular/common";
import localeIt from "@angular/common/locales/it";
import { SharedModule } from "@shared/shared.module";
import { httpInterceptorProviders } from "./interceptors";
import { NewimplantComponent } from "./newimplant/newimplant.component";

registerLocaleData(localeIt, "it");

const googleMapsParams = {
  apiKey: environment.google.apiKey,
  libraries: ["places"],
  language: "it",
  // region: 'IT'
  // region: 'DE'
};

export function authInit(authService: AuthService): any {
  return () => authService.checkBootLogin();
}

@NgModule({
  declarations: [AppComponent, NewimplantComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    PagesModule,
    HomeModule,
    TranslateModule.forRoot(),
    // Material moment date module
    MatMomentDateModule,
    // Material
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressBarModule,
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    SharedModule,
    // FuseThemeOptionsModule,
    // App modules
    LayoutModule,
    NgxSpinnerModule,
    // services
    // ServicesModule
    // AngularFireAuthModule,
    // AngularFireMessagingModule,
    // AngularFireModule.initializeApp(environment.firebase),
    ModaliModule,
    MultiFileUploaderModule,
    // AgmCoreModule.forRoot(googleMapsParams),
    // MatGoogleMapsAutocompleteModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  providers: [
    ApiService,
    CookieService,
    MessagingService,
    ModaliService,
    LoaderService,
    WeatherService,
    UploaderService,
    WebsocketService,
    BookingService,
    NavigationNotificationService,
    ErrorService,
    // GoogleMapsAPIWrapper,
    httpInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: authInit,
      multi: true,
      deps: [AuthService],
    },
    { provide: LOCALE_ID, useValue: "it" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
