export interface Generatore {
    matricola:      string;
    potenza:        number;
    pwutile:        number;
    datainst:       Date;
    datadism:       null;
    fabbricante:    string;
    modello:        string;
    idCombustibile: number;
    attivo:         number;
}
