import { Injectable } from '@angular/core';
import {ModaleClass, ModaleModel} from '../modali-classes';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ChangePasswordComponent } from './change-password.component';


@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService extends ModaleClass implements ModaleModel{

  public id = 'change-password';  

  constructor(public dialog: MatDialog ) {
    super(dialog);
  }

  open(params?: any): Observable<any> {
    return Observable.create((observer) => {
      
      // this.init();            
      this.openBox(ChangePasswordComponent, { width: '400px' });
      this.dialogRef.afterClosed().subscribe(
        () => {          
        }
      );
      observer.complete();
    });
  }


}
