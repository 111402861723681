<div id="login" fxLayout="column">
  <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
    <div
      id="login-form"
      [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }"
    >
      <div class="logo">
        <img src="/assets/images/logos/logocit.png" />
      </div>

      <!-- <div class="title">Benvenuto in helpdesk</div> -->

      <form name="loginForm" [formGroup]="loginForm" novalidate>
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" />
          <!-- <mat-icon matSuffix class="secondary-text">mail</mat-icon> -->
          <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
          <mat-error *ngIf="loginForm.get('email').hasError('required')">
            L'email è obbligatoria
          </mat-error>
          <mat-error
            *ngIf="
              !loginForm.get('email').hasError('required') &&
              loginForm.get('email').hasError('email')
            "
          >
            L'indirizzo email non è valido
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" />
          <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
          <mat-error> Password è obbligatoria </mat-error>
        </mat-form-field>

        <!-- <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
                    <mat-checkbox class="remember-me" aria-label="Remember Me">
                        Ricordami
                    </mat-checkbox>

                    <a class="forgot-password" [routerLink]="'/forgot-password'">
                        Password dimenticata?
                    </a>
                </div> -->

        <button
          type="button"
          mat-raised-button
          color="accent"
          class="submit-button"
          aria-label="LOG IN"
          (click)="login()"
          [disabled]="loginForm.invalid"
        >
          LOGIN
        </button>
      </form>

      <!-- <div class="separator">
                <span class="text">OR</span>
            </div>

            <button mat-raised-button class="google">
                Log in with Google
            </button>

            <button mat-raised-button class="facebook">
                Log in with Facebook
            </button> -->

      <!-- <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Non hai un account?</span>
                <a class="link" [routerLink]="'/register'">Crea un account</a>
            </div> -->
    </div>
  </div>
</div>
