import { Injectable } from '@angular/core';
import { BaseTable } from './base-table';
import { IndexDeclaration } from './indexing';
import { EnumItem } from '../models';

@Injectable({
  providedIn: 'root',
})
export class FluidiTableService extends BaseTable<EnumItem> {
  initialize(): void {
    // insert here the initizialitation of table like indexes
    this.primaryIndex = new IndexDeclaration('primary', 'value');  
    this.fieldSelectItemValue = 'value';
    this.fieldSelectItemDisplay = 'label';     
  }
}
