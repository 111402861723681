import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEventType,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Subject } from "rxjs";
import * as filesize from "filesize";

declare let config: any;

export enum UploadStatus {
  uploading,
  completed,
  error,
  abort,
}

export class UploadFileClass {
  progress: number;
  idtemp: number;
  status: UploadStatus;
  name: string;
  size: number;
  sizeDisplay: string;
  completed: boolean;
  error: boolean;
  errorDescription: string;
  constructor(file: File) {
    this.name = file.name;
    this.size = file.size;
    this.sizeDisplay = filesize(file.size);
    this.progress = 0;
    this.status = UploadStatus.uploading;
    this.completed = false;
    this.error = false;
    this.errorDescription = "";
  }

  setCompleted(idupload: number): void {
    this.status = UploadStatus.completed;
    this.idtemp = idupload;
    this.completed = true;
  }

  setError(error: any): void {
    this.error = true;
    this.errorDescription = error;
  }
}

@Injectable()
export class UploaderService {
  constructor(private http: HttpClient) {}

  public uploadMulti(files: Map<string, File>): {
    [key: string]: Observable<UploadFileClass>;
  } {
    // this will be the our resulting map
    const status = {};

    files.forEach((file, key) => {
      // create a new multipart-form for every file
      // console.log(file);

      // Save every progress-observable in a map of all observables
      status[key] = this.upload(file, true);
    });

    // return the map of progress.observables
    return status;
  }

  public upload(file: File, multi?: boolean): Observable<UploadFileClass> {
    // this will be the our resulting map

    // create a new multipart-form for every file
    // console.log(file);
    const fileProgress = new UploadFileClass(file);
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);

    // create a http-post request and pass the form
    // tell it to report the upload progress
    const req = new HttpRequest(
      "POST",
      config.api.url + "/store/action/upload",
      formData,
      {
        reportProgress: true,
        headers: new HttpHeaders({ ytoken: config.api.token }),
      }
    );

    // create a new progress-subject for every file
    const progress = new Subject<UploadFileClass>();

    // send the http-request and subscribe for progress-updates
    this.http.request(req).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          // calculate the progress percentage
          const percentDone = Math.round((100 * event.loaded) / event.total);
          fileProgress.progress = percentDone;
          // pass the percentage into the progress-stream
          progress.next(fileProgress);
        } else if (event instanceof HttpResponse) {
          fileProgress.setCompleted(event.body["id"]);
          progress.next(fileProgress);
          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          progress.complete();
        }
      },
      (error: any) => {
        fileProgress.setError(error.error);
        console.log(fileProgress);
        progress.next(fileProgress);
        if (!multi) {
          progress.error(error.error);
        } else {
          progress.complete();
        }
      }
    );

    // Save every progress-observable in a map of all observables

    return progress.asObservable();

    // return the map of progress.observables
  }
}
